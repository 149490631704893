import pt_PT from "@translations/pt_PT";
import { deepClone } from "@translations/utils";

const ipamPT = deepClone(pt_PT);

ipamPT.form.header.req = "Pedido de Informações";
ipamPT.form.header.reqSub =
	"Preencha o formulário com os seus dados para solicitar informações";
ipamPT.form.header.requestLong =
	"Preencha o formulário com os seus dados para solicitar informações";
ipamPT.form.header.subtitle =
	"Deixe-nos os seus dados e entraremos em contacto consigo brevemente";
ipamPT.form.fields.label.event = "Seleciona o evento";
ipamPT.form.fields.helptext.studentNumber =
	"Se não se recorda do seu número de estudante, por favor, contacte a linha de apoio ao estudante: +351 210 309 930 (Lisboa) ou + 351 229 398 089 (Porto)";
ipamPT.form.confirmation.error.studentNumberNotFound =
	"Numero de estudante não encontrado. Por favor, tente novamente";

export default ipamPT;
